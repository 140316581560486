var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"enroll-new-course-modal","ok-title":_vm.$t('general.save'),"size":"xl","centered":"","cancel-title":_vm.$t('general.cancel'),"title":_vm.$t('users.enroll_new_course'),"no-close-on-backdrop":""},on:{"ok":_vm.saveClick,"hidden":_vm.hideModal}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","sm":"12"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',{attrs:{"for":"search"}},[_vm._v(" "+_vm._s(_vm.$t('general.search'))+": ")]),_c('b-form-input',{attrs:{"id":"search"},on:{"input":_vm.searchCourses},model:{value:(_vm.query_params.search),callback:function ($$v) {_vm.$set(_vm.query_params, "search", $$v)},expression:"query_params.search"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{attrs:{"for":"subject"}},[_vm._v(" "+_vm._s(_vm.$t('references.subjects'))+": ")]),_c('v-select',{attrs:{"options":_vm.GET_SUBJECTS_LIST.results,"get-option-label":function (opt) { return opt.title[_vm.$i18n.locale]; },"reduce":function (opt) { return opt.id; },"multiple":""},on:{"input":_vm.searchCourses},model:{value:(_vm.query_params.subjects),callback:function ($$v) {_vm.$set(_vm.query_params, "subjects", $$v)},expression:"query_params.subjects"}})],1)],1),_c('b-table',{ref:"course-select-table",attrs:{"id":"course-select-table","striped":"","fields":_vm.fields,"items":_vm.getTableItems,"selectable":"","sticky-header":_vm.tableHeight},nativeOn:{"scroll":function($event){return _vm.handleScroll.apply(null, arguments)}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(subject)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject.title[_vm.$i18n.locale])+" ")]}},{key:"cell(teachers)",fn:function(props){return _vm._l((props.item.teachers),function(teacher){return _c('b-badge',{key:teacher.id},[_vm._v(" "+_vm._s(teacher.full_name)+" ")])})}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"p-50",attrs:{"variant":"primary","disabled":_vm.selected_courses.includes(item.id)},on:{"click":function($event){return _vm.addToSelected(item)}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)]}}])}),_c('b-overlay',{attrs:{"show":_vm.isBusy,"no-wrap":"","opacity":"0.5"}})],1),_c('b-col',{attrs:{"md":"12","sm":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('users.selected_courses')))]),_c('b-table',{ref:"course-select-table",attrs:{"striped":"","fields":_vm.selected_table_fields,"items":_vm.selected_courses},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(subject)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject.title[_vm.$i18n.locale])+" ")]}},{key:"cell(teachers)",fn:function(props){return [_c('v-select',{attrs:{"options":props.item.teachers,"label":"full_name","multiple":"","reduce":function (opt) { return opt.id; }},model:{value:(_vm.selected_courses[props.index].selected_teachers),callback:function ($$v) {_vm.$set(_vm.selected_courses[props.index], "selected_teachers", $$v)},expression:"selected_courses[props.index].selected_teachers"}})]}},{key:"cell(access)",fn:function(props){return [_c('date-picker',{attrs:{"other-props":{'is-range': true}},model:{value:(_vm.selected_courses[props.index].access),callback:function ($$v) {_vm.$set(_vm.selected_courses[props.index], "access", $$v)},expression:"selected_courses[props.index].access"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"p-50",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeCourse(item)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }