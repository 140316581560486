<template>
  <b-modal
    id="enroll-new-course-modal"
    :ok-title="$t('general.save')"
    size="xl"
    centered
    :cancel-title="$t('general.cancel')"
    :title="$t('users.enroll_new_course')"
    no-close-on-backdrop
    @ok="saveClick"
    @hidden="hideModal"
  >
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-row class="mb-1">
          <b-col md="3">
            <label for="search"> {{ $t('general.search') }}: </label>
            <b-form-input
              id="search"
              v-model="query_params.search"
              @input="searchCourses"
            />
          </b-col>
          <b-col md="3">
            <label for="subject"> {{ $t('references.subjects') }}: </label>
            <v-select
              v-model="query_params.subjects"
              :options="GET_SUBJECTS_LIST.results"
              :get-option-label="opt => opt.title[$i18n.locale]"
              :reduce="opt => opt.id"
              multiple
              @input="searchCourses"
            />
          </b-col>
        </b-row>
        <b-table
          id="course-select-table"
          ref="course-select-table"
          striped
          :fields="fields"
          :items="getTableItems"
          selectable
          :sticky-header="tableHeight"
          @scroll.native="handleScroll"
        >
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(subject)="{ item }">
            {{ item.subject.title[$i18n.locale] }}
          </template>
          <template #cell(teachers)="props">
            <b-badge
              v-for="teacher in props.item.teachers"
              :key="teacher.id"
            >
              {{ teacher.full_name }}
            </b-badge>
          </template>
          <template #cell(selected)="{ item }">
            <b-button
              variant="primary"
              class="p-50"
              :disabled="selected_courses.includes(item.id)"
              @click="addToSelected(item)"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </template>
        </b-table>
        <b-overlay
          :show="isBusy"
          no-wrap
          opacity="0.5"
        />
      </b-col>
      <b-col
        md="12"
        sm="12"
      >
        <h3>{{ $t('users.selected_courses') }}</h3>
        <b-table
          ref="course-select-table"
          striped
          :fields="selected_table_fields"
          :items="selected_courses"
        >
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(subject)="{ item }">
            {{ item.subject.title[$i18n.locale] }}
          </template>
          <template #cell(teachers)="props">
            <v-select
              v-model="selected_courses[props.index].selected_teachers"
              :options="props.item.teachers"
              label="full_name"
              multiple
              :reduce="opt => opt.id"
            />
            <!-- @input="selectTeacher($event, props)" -->
          </template>
          <template #cell(access)="props">
            <date-picker
              v-model="selected_courses[props.index].access"
              :other-props="{'is-range': true}"
            />
          </template>
          <template #cell(actions)="{ item }">
            <b-button
              variant="danger"
              class="p-50"
              @click="removeCourse(item)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BTable, BButton,
  // BFormCheckbox,
  BOverlay, BFormInput, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import datePicker from '@/components/VueDatePicker.vue'

export default {
  components: {
    BModal,
    BTable,
    // BFormCheckbox,
    BOverlay,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BBadge,
    BButton,
    datePicker,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    enrolledCourses: {
      type: Array,
      required: true,
    },
    searchResults: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    pageSize: {
      type: Number,
      required: false,
      default: 5,
    },
    renderIncrement: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected_rows: [],
      // items: [],
      isBusy: false,
      query_params: {
        search: '',
        subjects: [],
      },
      items: [],
      fields: [
        {
          key: 'title',
          label: 'users.course_title',
        },
        {
          key: 'price',
          label: 'users.price',
        },
        {
          key: 'subject',
          label: 'users.subject',
        },
        // {
        //   key: 'teachers',
        //   label: 'users.teachers',
        // },
        {
          key: 'selected',
          label: 'general.select',
        },
      ],
      selected_table_fields: [
        {
          key: 'title',
          label: 'users.course_title',
        },
        {
          key: 'price',
          label: 'users.price',
        },
        {
          key: 'subject',
          label: 'users.subject',
        },
        {
          key: 'teachers',
          label: 'users.teachers',
        },
        {
          key: 'access',
          label: 'users.access',
        },
        {
          key: 'actions',
          label: 'general.actions',
        },
      ],
      selected_courses: [],
      selections: [],
      search_result_courses: [],
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
    tableHeight() {
      return `${52 * this.pageSize}px`
    },
    getTableItems() {
      if (this.query_params.search || this.query_params.subjects.length) {
        return this.searchResults.filter(el => this.enrolledCourses.every(rel => rel.course.id !== el.id))
      }
      return this.items.filter(el => this.enrolledCourses.every(rel => rel.course.id !== el.id))
    },
    selectedCourseIds() {
      return this.selected_courses.map(el => el.id)
    },
  },
  watch: {
    /* Optionally hide scrollbar when loading */
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs['course-select-table'].$el
        if (newVal === true) {
          tableScrollBody.classList.add('overflow-hidden')
        } else {
          tableScrollBody.classList.remove('overflow-hidden')
        }
      }
    },
    renderIncrement() {
      if (!this.query_params.search && !this.query_params.subjects.length) {
        this.items = this.items.concat(this.courses)
        // this.selected_rows = this.items
        if (this.getTableItems.length < 5) {
          this.$emit('scrollEnd')
        }
      }
    },
  },
  mounted() {
    this.FETCH_SUBJECTS_LIST({ page_size: 1000 })
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST']),
    saveClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      const reqBody = []
      this.selected_courses.forEach(element => {
        // const { course: id,  } = element
        reqBody.push({
          course: element.id,
          teachers: element.selected_teachers,
          access_start_date: element.access.start,
          access_end_date: element.access.end,
        })
      })
      console.log(reqBody)
      this.$emit('saveClick', reqBody)
    },
    searchCourses() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('search', this.query_params)
      }, 300)
    },
    addToSelected(item) {
      if (!this.selectedCourseIds.includes(item.id)) {
        this.selected_courses.push(this.$_removeObjectConnection(item))
      }
    },
    // selectTeacher(evt, row) {
    //   if (evt.length) {
    //     this.selected_rows.push({ course: row.item.id, teachers: evt, is_individual: true })
    //   } else {
    //     const ind = this.selected_rows.indexOf(row.item)
    //     this.selected_rows.splice(ind, 1)
    //   }
    // },
    handleScroll(e) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        if (!this.isBusy) {
          this.$emit('scrollEnd')
        }
      }
    },
    removeCourse(item) {
      const ind = this.selected_courses.indexOf(item)
      const row_ind = this.selected_rows.indexOf(item.id)
      this.selected_courses.splice(ind, 1)
      this.selected_rows.splice(row_ind, 1)
    },
    hideModal() {
      // this.selected_rows = []
      this.selected_courses = []
    },
  },
}
</script>

<style lang="scss">
</style>
