<template>
  <b-modal
    id="AddEditClassModal"
    :title="$t('classes.add_class')"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    centered
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="saveClass"
    @cancel="$bvModal.hide('AddEditClassModal')"
  >
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          cols="12"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('classes.class_title')"
            rules="required"
          >
            <b-form-group
              :label="$t('classes.class_title')"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="oneClass.title"
                :placeholder="$t('classes.class_title')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('classes.capacity')"
            rules="required"
          >
            <b-form-group
              :label="$t('classes.capacity')"
              label-for="max_students"
            >
              <b-form-input
                id="max_students"
                v-model="oneClass.capacity"
                type="number"
                :placeholder="$t('classes.capacity')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    defData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      oneClass: {
        title: '',
        capacity: null,
      },
      required,
    }
  },
  watch: {
    defData(val) {
      if (val) {
        const {
          id, title, capacity, start_time, end_time,
        } = val

        this.oneClass = {
          id, title, capacity, start_time, end_time,
        }
      }
    },
  },
  methods: {
    ...mapActions('classes', ['FETCH_CLASS', 'CREATE_CLASS', 'UPDATE_CLASS']),

    saveClass() {
      this.$refs.simpleRules.validate().then(succes => {
        if (succes) {
          if (!this.isEdit) {
            this.CREATE_CLASS(this.oneClass)
              .then(res => {
                this.$_successToast(this.$t('general.added'))
                this.$bvModal.hide('AddEditClassModal')
                this.$nextTick(() => {
                  this.$router.push(`/class/class-info/${res.id}`)
                })
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          } else {
            this.UPDATE_CLASS(this.oneClass)
              .then(() => {
                this.$_successToast(this.$t('general.edited'))
                this.$emit('refresh')
                this.$bvModal.hide('AddEditClassModal')
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          }
        }
      })
    },
    onShow() {
      if (!this.isEdit) {
        this.oneClass = {
          title: '',
          capacity: 0,
        }
      }
    },
  },
}
</script>

<style lang="scss">

</style>
